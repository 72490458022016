import styled, { css } from "styled-components";
import ArrowRight from '../../assets/icons/arrow-right.svg';
import { Container } from "../index.style";
import { ZoomableImageContainer } from "../right-menu/index.styles";

const CurrentSnpWrapper = styled.div<{ bgImage: string }>`
  width: 20rem;
  height: 6.25rem;
  background: url(${({ bgImage }) => bgImage});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 1.25rem;
  display: flex;
  gap: 0.625rem;
  align-items: center;
  border-radius: 0.625rem;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 0.625rem;
  }
`;

const Image = styled.img< { noShadow?: boolean } > `
  width: 3.875rem;
  height: 3.875rem;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.50);
  ${({ noShadow }) => noShadow && css`
    box-shadow: none;`
  }
  z-index: 20;
`;

const SnpInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
  color: var(--white);
  z-index: 20;

  .name {
    font-size: 1.125rem;
    font-weight: 600;
  }

  .region {
    font-size: 0.875rem;
    font-weight: 300;
  }
`;

const MenuHeader = styled(Container) <{ active: boolean }>`
  gap: 1.25rem;
  justify-content: space-between;
  align-items: center;
  .burger-container {
    width: 27px;
    height: 27px;
    display: flex;
    justify-content: center;
    align-items: center;

    .burger{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      height: 11px;
    }

    span {
      width: 16px;
      height: 2px;
      background-color: #818689;
    }
  }

  ${({ active }) => active && css`
    .burger-container {
      background: linear-gradient(180deg, #2366CA 0%, #02AC51 100%);
      border-radius: 5px;
      span {
        background-color: var(--white);
      }

      span:nth-child(1) {
        transform: rotate(-45deg) translate(-4px, 3px);
      }

      span:nth-child(2) {
        opacity: 0;
      }

      span:nth-child(3) {
        transform: rotate(45deg) translate(-3px, -3px);
      }
    }
    `}

  img {
    width: 11.25rem;
    height: auto;
  }
`;

const SwitchContainer = styled.label<{ checked?: boolean }>`
  position: relative;
  display: inline-block;
  width: 55px;
  height: 27px;
  border-radius: 30px;
  border: 1px solid #8F949C;
  background: var(--white);

  input {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked + .slider:before {
      -webkit-transform: translateX(27px);
      -ms-transform: translateX(27px);
      transform: translateX(27px);
    }
  }

  ${({ checked }) => checked && css`
  input {
    opacity: 0;
    width: 0;
    height: 0;

    & + .slider:before {
      -webkit-transform: translateX(27px);
      -ms-transform: translateX(27px);
      transform: translateX(27px);
    }
  }
  `}

  .slider{
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 34px;
    &:before {
      color: #8F949C;
      position: absolute;
      content: "";
      height: 26px;
      width: 26px;
      bottom: 0px;
      background: #8F949C;
      -webkit-transition: .4s;
      transition: .4s;
      border-radius: 50%;
    }
  }
`;


const LanguageText = styled.span<{ position?: 'left' | 'right', isSelected?: boolean }>`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: ${({ isSelected }) => isSelected ? 'var(--white)' : '#8F949C'};
  font-size: 0.75rem;

  ${({ position }) => position === 'left' && css`
    left: 6px;
  `}

  ${({ position }) => position === 'right' && css`
    right: 6px;
  `}
`;

const LeftMenuContainer = styled.div<{ active?: boolean, isShown: boolean }>`
  width: 21.25rem;
  background: ${({ active }) => !active ? 'var(--bg)' : 'var(--lightYellow)'};
  border-radius: 0.625rem;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.30);
  padding: 0.625rem;
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
  z-index: 10;
  position: absolute;
  margin: 0 0.625rem 0.625rem 0.625rem;
  height: calc(100vh - 0.3125rem);

  @media (min-width: 1367px) and (max-width: 1441px) {
    margin 0.625rem;  
  }
  
  ::-webkit-scrollbar {
    width: 2px;
    margin-right: 0.625rem;
  }
  
  ::-webkit-scrollbar-track {
    background-color: transparent; /* You can set a background color here if needed */
    margin-right: 0.625rem;

  }
  
  ::-webkit-scrollbar-thumb {
    background-color: var(--gray);
    border-radius: 5px; 
    border-top: 15px solid transparent;
    border-bottom: 35px solid transparent;
    background-clip: padding-box;
  }

  .add-info{
    text-align: center;
    font-size: 0.75rem;
  }

  .error {
    text-align: center;
    color: red;
  }

  .scrollable {
    display: flex;
    flex-direction: column;
    gap: 0.625rem;
    max-height: 100%;
    overflow-y: auto;
    min-height: 20rem;
    padding-right: 0.3125rem;

    ::-webkit-scrollbar {
      width: 2px;
      margin-right: 0.625rem;
    }
  
    ::-webkit-scrollbar-track {
      background-color: transparent; /* You can set a background color here if needed */
      margin-right: 0.625rem;

    }
    
    ::-webkit-scrollbar-thumb {
      background-color: var(--gray);
      border-radius: 5px; 
      border-top: 15px solid transparent;
      border-bottom: 35px solid transparent;
      background-clip: padding-box;
    }
  }

  ${({ isShown }) => !isShown && css`
    display: none;`
  }
`;

const TabContainer = styled.div`
  display: flex;
  gap: 0.625rem;
  width: 20rem;
  padding: 0 0.625rem;
  border-bottom: 1px solid var(--borderGray);
`;

const Tab = styled.div<{ isSelected: boolean }>`
  color: var(--shuttleGray);
  font-size: 0.75rem;
  height: 1.875rem;
  line-height: 1.875rem;
  cursor: pointer;

  ${({ isSelected }) => isSelected && css`
    color: var(--text-color);
    border-bottom: 1px solid var(--text-color);
    font-weight: 700;
  `}
`;

const TabContentContainer = styled(Container)`
  margin-top: -0.625rem;
  border-bottom: 2px solid var(--borderGray);
  border-radius: 0px 0px 10px 10px;
  padding: 0;
`;

const NavBarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.3125rem;
  padding: 5px 10px;
`;

const NavIcon = styled.div`
  width: 45px;
  height: 45px;
  border-radius: 50%;
  border: 1px solid var(--borderGray);
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--white);

  img {
    width: 1.25rem;
    height: 1.25rem;
  }
`;

const NavItem = styled.div<{ isSelected: boolean }>`
  display: flex;
  gap: 0.625rem;
  width: 18.75rem;
  align-items: center;
  height: 2.8125rem;
  cursor: pointer;

  .text {
    font-size: 0.875rem;
    color: var(--shuttleGray);
  }

  ${({ isSelected }) => isSelected && css`
  background: linear-gradient(180deg, #226AC3 0%, #04A958 100%);
    border-radius: 6.25rem;
    position: relative;

    .text {
      color: var(--white);
      font-weight: 600;
    }

    ${NavIcon} {
      border: 1px solid #07A561;
    }

    &:after{
      content: url(${ArrowRight});
      position: absolute;
      color: var(--white);
      right: 1.25rem;
      width: 0.5rem;
      height: 1rem;
      top: 0.75rem;
    }
  `}
`;

const PassportContainer = styled.div`
  flex-direction: column;
  align-items: center;
  gap: 0.625rem;
  max-height: 15.5rem;
  background: var(--bg-lighter);

  ::-webkit-scrollbar {
    width: 2px;
  }
  
  ::-webkit-scrollbar-track {
    background-color: transparent; /* You can set a background color here if needed */
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: var(--gray);
    border-radius: 5px; 
    border-top: 15px solid transparent;
    border-bottom: 35px solid transparent;
    background-clip: padding-box;    
  }

  .divider {
    width: 18.75rem;
    height: 1px;
    background: var(--borderGray);
  }

  ${ZoomableImageContainer}{
    height: 60vh;
    width: 100%;
    img {
      height: 100%;
    }
  }
`;

const Item = styled.div`
  padding: 0.625rem;
  display: flex;
  gap: 0.625rem;
  align-items: flex-start;
  width: 100%;
  background-color: var(--bg-lighter);
  border-radius: 10px;
`;

const Photo = styled.img`
  width: 3.75rem;
  height: 5rem;
  border-radius: 10px;
  border: 1px solid var(--borderGray);
  сursor: pointer;
`;

const PersonInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.4375rem;

  .group {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
  }

  .name {
    font-size: 0.875rem;
    font-weight: 700;
    color: var(--text-color);
    text-transform: capitalize;
  }

  .position {
    font-size: 0.75rem;
    color: var(--shuttleGray);
  }

  .gradient-divider {
    width: 100%;
    height: 1px;
    background: linear-gradient(90deg, var(--borderGray) 0%, var(--white) 100%);
  }

  .date {
    font-size: 0.75rem;
    color: var(--text-color);
    font-weight: 700;
  }

  .phone {
    display: flex;
    gap: 0.625rem;
    align-items: center;

    a.phone-number {
      color: var(--linkBlue);
      font-size: 0.875rem;
      font-weight: 700;
      display: flex;
      gap: 0.625rem;
      text-decoration: underline;
    }
    
    img {
      width: 0.875rem;
      height: 0.875rem;
    }
  }

`;

const SearchInputWrapper = styled.div`
  position: relative;
  background: var(--bg-lighter);
  input {
    width: 20rem;
    height: 3rem;
    padding: 10px 10px 10px 20px;
    border: 1px solid var(--borderGray);
    border-radius: 8px;
    font-size: 0.875rem;
    color: var(--text-color);
    &:focus {
      outline: none;
    }
    background: var(--bg-lighter);
  }
`;

const InputIcons = styled.div`
  position: absolute;
  top: 0.625rem;
  right: 0.625rem;
  display: flex;
  gap: 0.625rem;
  height: 1.75rem;

  img {
    cursor: pointer;
  }

  .divider{
    width: 1px;
    height: 1.75rem;
    background: var(--borderGray);
  }
`;

const SnpContainer = styled.div`
  padding: 0.625rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.3125rem;
  max-height: 15.5rem;
  overflow-y: auto;
  overflow-x: hidden;

  ::-webkit-scrollbar {
    width: 2px;
  }
  
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: var(--gray);
    border-radius: 5px; 
    border-top: 15px solid transparent;
    border-bottom: 35px solid transparent;
    background-clip: padding-box;    
  }
`;

const SnpItem = styled.div<{ withIcon?: boolean }>`
  padding: 0.3125rem 0.625rem;
  width: 18.75rem;
  background-color: var(--white);
  border-radius: 10px;
  font-size: 0.75rem;
  line-height: 0.875rem;

  span {
     font-size: 0.75rem;
  }

  b {
    font-size: 0.75rem;
    line-height: 0.875rem;
  }

  img { 
    margin-right: 0.625rem;
  }

  .bold {
    font-weight: 700;
    font-size: 0.75rem;
  }

  strong {
    font-size: 0.75rem;
  }

  ${({ withIcon }) => withIcon && css`
    display: flex;
    align-items: center;
    gap: 0.625rem;
  `}
`;

const LoginContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  margin-top: 0.625rem;
  min-height: 32vh;
  max-height: 100%;
  .block {
    border-radius: 8px;
    border: 1px solid #E2DEC5;
    padding: 0.9375rem;
    width: 100%;
    display: flex;
    gap: 0.625rem;
    flex-direction: column;

    .heading {
      font-size: 0.875rem;
      display: flex;
      gap: 0.625rem;
      color: var(--text-color);
      align-items: center;
    }

    &:nth-child(2) {
      .heading {
        color: var(--shuttleGray);
      }
    }

    input { 
      border-radius: 10px;
      border: 1px solid #818689;
      background: var(--white);
      padding: 0.9375rem 1.25rem;
      color: #78858F;
      font-size: 0.875rem;
      line-height: 10px;
      height: 2.5rem;
      &:focus {
        outline: none;
      }

      &.input-error {
        color: red;
        border: 1px solid red;
      }
    }

    button {
      display: flex;
      gap: 0.625rem;
      align-items: center;
      justify-content: center;
      height: 3rem;
      border-radius: 0.625rem ;
      border: 1px solid var(--borderGray);
      background: var(--white);
      cursor: pointer;
      text-transform: uppercase;
    }
  }

  .username {
    color: var(--text-color);
    font-size: 1  rem;
  }
`;

const OptionsList = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 100000;
  width: 100%;
  max-height: 26rem;
  overflow-y: auto;
  background-color: var(--bg-white);
  border: 1px solid #ccc;
  border-top: none;
  border-radius: 0 0 5px 5px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  ::-webkit-scrollbar {
    width: 2px;
    margin-right: 0.625rem;
  }
  
  ::-webkit-scrollbar-track {
    background-color: transparent;
    margin-right: 0.625rem;

  }
  
  ::-webkit-scrollbar-thumb {
    background-color: var(--gray);
    border-radius: 5px; 
    border-top: 15px solid transparent;
    border-bottom: 35px solid transparent;
    background-clip: padding-box;
  }

  .item {
    padding: 0.625rem;
    cursor: pointer;
    transition: background-color 0.2s;
    font-size: 0.75rem;
    color: var(--linkBlue);
    font-weight: 700;
    
    &:hover {
      background-color: var(--whiteSmoke);
    }

    &.disabled {
      color: var(--gray);
      cursor: auto;
    }

    &.child {
      padding-left: 1.25rem;
    }
  }

  .add-label {
    font-size: 0.75rem;
    color: #818689;
    font-weight: 400;
  }
`
const ThemeSwitchContainer = styled.div`
 .checkbox {
  display: none;
}

.switch {
  background-color: #111;
  border-radius: 50px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  position: relative;
  height: 39px;
  width: 75px;
  /* Scale the size to your need */
  transform: scale(0.7);
}

.switch .ball {
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  top: 2px;
  left: 2px;
  height: 33px;
  width: 33px;
  transform: translateX(0px);
  transition: transform 0.2s linear;
}

.checkbox:checked + .switch .ball {
  transform: translateX(36px);
}

.checkbox:checked + .switch  {
  background-color: #2196f3;
}

.icon {
  font-size: 24px;
}

`
export {
  CurrentSnpWrapper,
  Image,
  SnpInfo,
  MenuHeader,
  SwitchContainer,
  LanguageText,
  LeftMenuContainer,
  TabContainer,
  Tab,
  TabContentContainer,
  NavBarWrapper,
  NavItem,
  NavIcon,
  PassportContainer,
  Item,
  Photo,
  PersonInfo,
  SearchInputWrapper,
  InputIcons,
  SnpContainer,
  SnpItem,
  LoginContainer,
  OptionsList,
  ThemeSwitchContainer
}