/* eslint-disable react-hooks/exhaustive-deps */
import React, { ChangeEvent, Fragment, useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Footer, FooterButton, FormBlock, FormGroup, Wrapper } from '../../components/admin-page/admin-page.styles';
import { Form, Formik, Field } from 'formik';
import { GoUp, Save } from '../../assets/icons';
import { checkVal, currentYear, getLang, getPopulationDetails, handleGoUp, isRegion, t, updateKatoAccessList } from '../../utils/helpers.utils';
import { toast, ToastContainer } from 'react-toastify';
import { getGeneralInfo } from '../../requests/snp.request';
import { updateFormPartial } from '../../requests/supervisor.request';
import { IPopulationDetails, IPopulationDynamic } from '../../interfaces/snp.interface';

const defaultDetails: IPopulationDetails = {
  id: 1,
  populationCount: 0,
  maleCount: 0,
  femaleCount: 0,
  birthCount: 0,
  deathCount: 0,
  immigrationCount: 0,
  emigrationCount: 0,
  saldoMigration: 0,
  populationEmploymentCount: 0,
  economicallyActivePopulation: 0,
  youthPopulationCount: 0,
  employedPopulationCount: 0,
  hiredPopulationCount: 0,
  selfEmployedPopulationCount: 0,
  unemployedPopulationCount: 0,
}

const PopulationDynamicPage = () => {
  const { i18n: { language } } = useTranslation();
  const navigate = useNavigate();
  const { kato } = useParams();

  const formikRef = useRef<any>(null);
  const wrapperRef = useRef<any>(null);

  const [regions, setRegions] = useState<any[]>([]);
  const [snps, setSnps] = useState<any[]>([]);
  const [region, setRegion] = useState<number>(0);
  const [snp, setSnp] = useState<number>(0);
  const [katoAccessList, setKatoAccessList] = useState<number[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isKatoRegion, setIsKatoRegion] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const [populationIncreaseDynamicStats, setPopulationIncreaseDynamicStats] = useState<IPopulationDynamic[]>([]);
  const [populationDetailsStats, setPopulationDetailsStats] = useState<IPopulationDetails>(defaultDetails);

  const validateForm = (data: any) => {
    setErrors({});
    let res = true;
    const populationDynamicData = populationIncreaseDynamicStats;
    if (populationDynamicData.some((item: any) => checkVal(item.value))) {
      for (const item of populationDynamicData) {
        if (!checkVal(item.value) && +item.year !== +currentYear) {
          setErrors((prev: any) => ({ ...prev, [`population-dynamic-${item.year}`]: true }))
          toast.error(t(`population-dynamic.error`, language).replace('{{ year }}', item.year));
          return false;
        }
      }
    }

    const populationDetailsDatakeys = Object.keys(populationDetailsStats).filter((key) => !['id', 'populationCount', 'saldoMigration', 'economicallyActivePopulation'].includes(key));
    if (populationDetailsDatakeys.some((key: any) => checkVal(populationDetailsStats[key as keyof IPopulationDetails]))) {
      for (const key of populationDetailsDatakeys) {
        if (!checkVal(populationDetailsStats[key as keyof IPopulationDetails])) {
          setErrors((prev: any) => ({ ...prev, [key]: true }))
          toast.error(t(`populationDetailsStats.errors.` + key, language));
          return false;
        }
      }
    }

    return res;
  }

  const handleSubmitForm = (values: any) => {
    setErrors({});

    const data = {
      populationIncreaseDynamicStats,
      populationDetailsStats
    }

    if (kato && validateForm(data)) {
      updateFormPartial(kato, data)
        .then(() => {
          toast.success(t('toast.save_success'))
          loadForm();
        })
        .catch(() => toast.error('Ошибка при сохранении'));
    }
  }

  const handleRegionChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setRegion(+e.target.value);
    setSnps(regions.find((item) => +item.value === +e.target.value)?.children || []);
    kato && isRegion(+kato) && navigate(`/admin/${e.target.value}/population-dynamic`)
  }

  const handleSnpChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setSnp(+e.target.value)
    navigate(`/admin/${e.target.value}`)
  }

  const loadForm = useCallback(() => {
    const val = kato ? kato : snp
    val && getGeneralInfo(+val).then((res: any) => {
      setPopulationIncreaseDynamicStats(res.populationIncreaseDynamicStats);
      setPopulationDetailsStats(res.populationDetailsStats);
      setIsLoading(false);
    });

  }, [region, snp, kato])

  const renderSelects = (lang: 'Ru' | 'Kz' = 'Ru') => {
    return (
      <div className="grid-item">
        <FormGroup>
          <label htmlFor="region">{t(`form.region.name`, lang)}</label>
          <Field as="select" value={region} onChange={handleRegionChange} disabled={lang.toLowerCase() !== language}>
            {regions.map((item) => <option key={item.value} value={item.value}>{lang === 'Kz' ? item.labelKz : item.label}</option>)}
          </Field>
        </FormGroup>

        {!isKatoRegion && <FormGroup>
          <label htmlFor="snp">{t(`form.snp.name`, lang)}</label>
          <Field as="select" value={snp} onChange={handleSnpChange} disabled={lang.toLowerCase() !== language}>
            {snps.map((item) => <option key={item.value} value={item.value}>{lang === 'Kz' ? item.labelKz : item.label}</option>)}
          </Field>
        </FormGroup>}
      </div>
    )
  }

  const renderFields = (lang: 'Ru' | 'Kz', setFieldValue: any) => {
    return <div className="grid-item">
      {populationDetailsStats && populationIncreaseDynamicStats && populationIncreaseDynamicStats.length !== 0 &&
        <FormBlock type="white">
          <div className="title bold">{t('population-dynamic.title')}</div>
          {populationIncreaseDynamicStats.sort((a, b) => a.year - b.year).map((item: IPopulationDynamic, index: number) => (
            <FormGroup type='row' key={index}>
              <label
                htmlFor={`populationIncreaseDynamicStats[${index}].value`}
                className="required"
              >
                {item.year}
              </label>
              <Field
                name={`populationIncreaseDynamicStats[${index}].value`}
                type="number"
                as="input"
                className={`${errors[`population-dynamic-${item.year}`] ? 'error' : ''}`}
                placeholder={t(`population-dynamic.placeholder${item.year > currentYear ? '-predict' : ''}`, lang).replace('{{ year }}', item.year)}
                min={0}
                value={item.year === currentYear ? getPopulationDetails(populationDetailsStats, 'populationCount') : item.value}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setFieldValue(`populationIncreaseDynamicStats[${index}].value`, e.target.value);
                  setPopulationIncreaseDynamicStats((prev: any) => {
                    const data = [...prev];
                    data[index].value = e.target.value;
                    return data;
                  });
                }}
                disabled={item.year === currentYear}
              />
            </FormGroup>
          ))}
        </FormBlock>}

      {
        populationDetailsStats && <FormBlock type="white">
          <FormBlock type="white">
            <div className="title bold">{t('populationDetailsStats.population')}</div>

            {['maleCount', 'femaleCount', 'birthCount', 'deathCount', 'immigrationCount', 'emigrationCount'].map((key: string) => (
              <>
                <FormGroup>
                  <label htmlFor={`populationDetailsStats.${key}`} className="required">
                    {t(`populationDetailsStats.${key}`, lang)}
                  </label>
                  <Field
                    name={`populationDetailsStats.${key}`}
                    type="number"
                    as="input"
                    className={`${errors[key] ? 'error' : ''}`}
                    onChange={(e: any) => {
                      const val = e.target.value;
                      setFieldValue(`populationDetailsStats.${key}`, val);
                      setPopulationDetailsStats({ ...populationDetailsStats, [key]: val });
                    }}
                    value={populationDetailsStats[key as keyof IPopulationDetails]}
                  />
                </FormGroup>

                {
                  key === 'femaleCount' && <FormGroup>
                    <label htmlFor={`populationDetailsStats.populationCount`}>
                      {t(`populationDetailsStats.populationCount`, lang)}
                    </label>
                    <Field
                      name={`populationDetailsStats.populationCount`}
                      type="number"
                      as="input"
                      className={`${errors[`populationCount`] ? 'error' : ''}`}
                      disabled
                      value={getPopulationDetails(populationDetailsStats, 'populationCount') || 0}
                    />
                  </FormGroup>
                }

                {
                  key === 'emigrationCount' && <FormGroup>
                    <label htmlFor={`populationDetailsStats.saldoMigration`}>
                      {t(`populationDetailsStats.saldoMigration`, lang)}
                    </label>
                    <Field
                      name={`populationDetailsStats.saldoMigration`}
                      type="number"
                      as="input"
                      className={`${errors[`saldoMigration`] ? 'error' : ''}`}
                      disabled
                      value={getPopulationDetails(populationDetailsStats, 'saldoMigration') || 0}
                    />
                  </FormGroup>
                }
              </>
            ))}


          </FormBlock>

          <FormBlock type='white'>
            <div className="title bold">{t('populationDetailsStats.employment')}</div>

            {['populationEmploymentCount', 'youthPopulationCount', 'employedPopulationCount', 'hiredPopulationCount', 'selfEmployedPopulationCount', 'unemployedPopulationCount'].map((key: string) => (
              <>
                <FormGroup>
                  <label htmlFor={`populationDetailsStats.${key}`} className="required">
                    {t(`populationDetailsStats.${key}`, lang)}
                  </label>
                  <Field
                    name={`populationDetailsStats.${key}`}
                    type="number"
                    as="input"
                    className={`${errors[key] ? 'error' : ''}`}
                    onChange={(e: any) => {
                      const val = e.target.value;
                      setFieldValue(`populationDetailsStats.${key}`, val);
                      setPopulationDetailsStats({ ...populationDetailsStats, [key]: val });
                    }}
                    value={populationDetailsStats[key as keyof IPopulationDetails]}
                  />
                </FormGroup>

                {
                  key === 'populationEmploymentCount' && <FormGroup>
                    <label htmlFor={`populationDetailsStats.economicallyActivePopulation`}>
                      {t(`populationDetailsStats.economicallyActivePopulation`, lang)}
                    </label>
                    <Field
                      name={`populationDetailsStats.economicallyActivePopulation`}
                      type="number"
                      as="input"
                      className={`${errors[`economicallyActivePopulation`] ? 'error' : ''}`}
                      disabled
                      value={getPopulationDetails(populationDetailsStats, 'economicallyActivePopulation') || 0}
                    />
                  </FormGroup>
                }
              </>
            ))}
          </FormBlock>
        </FormBlock>
      }
    </div>
  }

  useEffect(() => {
    loadForm();
  }, [loadForm, snp]);

  useEffect(() => {
    kato && updateKatoAccessList(katoAccessList, kato, navigate, setRegions, setRegion, setSnps, setSnp);
  }, [katoAccessList, kato]);

  useEffect(() => {
    if (kato) {
      setIsKatoRegion(isRegion(+kato))
    }
  }, [kato])

  useEffect(() => {
    const item = localStorage.getItem('user');
    if (item) {
      const snpInfo = JSON.parse(item);
      if (snpInfo && snpInfo.kato_access) {
        setKatoAccessList(snpInfo.kato_access)
      }
    }
  }, [])

  return (
    <div style={{ position: 'relative' }}>
      {
        !isLoading && <>
          <Wrapper ref={wrapperRef}>
            <Formik
              initialValues={{}}
              onSubmit={(values) => {
                handleSubmitForm(values);;
              }}
              innerRef={formikRef}
            >
              {({ setFieldValue }) => (
                <Form>
                  {renderSelects(getLang())}
                  {renderSelects(getLang() !== 'Kz' ? 'Kz' : 'Ru')}
                  {renderFields(getLang(), setFieldValue)}
                  {renderFields(getLang() !== 'Kz' ? 'Kz' : 'Ru', setFieldValue)}
                  <Footer>
                    <div className="buttons">
                      <FooterButton variant="save"><Save /> {t('save', language)}</FooterButton>
                    </div>
                    <div className="buttons">
                      <FooterButton variant="go-up" type='button' onClick={() => handleGoUp(wrapperRef)}><GoUp /> {t('go-up', language)}</FooterButton>
                    </div>
                  </Footer>
                </Form>
              )}
            </Formik>
          </Wrapper>
        </>
      }
      <ToastContainer />
    </div>
  )
}

export default PopulationDynamicPage