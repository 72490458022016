import React, { ChangeEvent } from 'react';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import Logo from '../../assets/images/Logo.svg';
import LogoRu from '../../assets/images/Logo-ru.svg'

import { MenuHeader, SwitchContainer, LanguageText } from './index.styles';
import ThemeSwitch from './theme-switch.component';

interface IProps {
  chosenLanguage: string,
  onChange: (e: ChangeEvent<HTMLInputElement>) => void,
  showLogin: boolean,
  setShowLogin: (show: boolean) => void
}

const Header: React.FC<IProps> = ({ chosenLanguage, onChange, showLogin, setShowLogin }) => {
  const { i18n: { language } } = useTranslation();
  const navigate = useNavigate();

  const handleLogoClick = () => {
    navigate('/');
  }

  return (
    <MenuHeader active={showLogin}>
      <div className="burger-container" onClick={() => setShowLogin(!showLogin)}>
        <div className="burger">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
      <img src={language === 'ru' ? LogoRu : Logo} alt="eAkimat Logo" onClick={handleLogoClick} />
      
      <SwitchContainer checked={chosenLanguage === 'RU'}>
        <input type="checkbox" onChange={onChange} checked={chosenLanguage === 'RU'} />
        <span className="slider">
          <LanguageText
            position='left'
            isSelected={chosenLanguage === 'KZ'}>
            KZ
          </LanguageText>
          <LanguageText
            position='right'
            isSelected={chosenLanguage === 'RU'}>
            RU
          </LanguageText>
        </span>
      </SwitchContainer>
    </MenuHeader>
  )
}

export default Header;