import React, { memo, useState } from 'react'
import { useTranslation } from 'react-i18next';

import { IPeople } from '../../interfaces/snp.interface';
import PhotoPlaceholder from '../../assets/images/photo-placeholder.png';
import { PassportContainer, Item, Photo, PersonInfo } from './index.styles'
import ZoomableImageModal from '../right-menu/zoomable-image.component';
import { capitalize, formatDate, getLang, keepCapitalized } from '../../utils/helpers.utils';
import { Phone } from '../../assets/icons';
import { getImageUrl } from '../admin-page/general-info.component';

interface IProps {
  people: IPeople,
}

const Passport: React.FC<IProps> = memo(({ people }) => {
  const { t } = useTranslation()

  const [selectedImage, setSelectedImage] = useState<number | null>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const openImageModal = (imageId: number) => {
    setIsOpen(true);
    setSelectedImage(imageId);
  }

  const closeImageModal = () => {
    setIsOpen(false);
    setSelectedImage(null);
  }

  return (
    <PassportContainer>
      {people && people.length > 0 && people.map((person, index) =>
        <React.Fragment key={`${person.nameRu}_${index}_${Math.random()}`}>
          <Item style={{ gap: '0.25rem' }}>
            <Photo
              src={(person.imageId && getImageUrl(+person.imageId)) || PhotoPlaceholder}
              alt='profile photo' onClick={() => person.imageId && openImageModal(+person.imageId)}
              style={{ width: '5.625rem', height: 'auto' }}
            />
            <PersonInfo>
              <div className='group'>
                <div className='name'>{person.type === 'p' && !person[`name${getLang()}`] ? t('form.policeObj.no') : capitalize(person[`name${getLang()}`] as string)}</div>
                {!(person.type === 'p' && !person[`name${getLang()}`]) && <div className='position'>{keepCapitalized(person[`position${getLang()}`] as string)}</div>}
              </div>

              {
                !(person.type === 'p' && !person[`name${getLang()}`]) && <>
                  <div className='gradient-divider'></div>

                  <div className='group'>
                    <div className='position'>{t('people.worksFrom')}:
                      <span className='date'> {person.inPositionFrom && formatDate(person.inPositionFrom)}</span>
                    </div>

                    <div className="phone">
                      <Phone />
                      <a className='phone-number' href={`tel:${person.phone}`}>{person.phone}</a>
                    </div>
                  </div>
                </>
              }

            </PersonInfo>
          </Item>
          {index !== people.length - 1 && <div className='divider'></div>}
        </React.Fragment>
      )}

      {selectedImage && (
        <ZoomableImageModal
          isFileUpload={true}
          isOpen={isOpen}
          onClose={closeImageModal}
          selectedImage={selectedImage}
          setSelectedImage={setSelectedImage}
          currentImageList={[]}
        />
      )}

    </PassportContainer>
  )
});

export default Passport