import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import SearchIcon from '../../assets/icons/search.svg'
import XIcon from '../../assets/icons/x.svg'

import { InputIcons, OptionsList, SearchInputWrapper } from './index.styles'
import { ISnp } from '../../interfaces/snp.interface';
import { getLang, OBLAST_KATO } from '../../utils/helpers.utils';

interface IProps {
  searchValue: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClear: () => void;
  options: any[];
  onSelectOption: (option: { label: string, value: ISnp }) => void;
}

export const aktobeOblast = { kato: OBLAST_KATO, nameRu: 'Актюбинская область', nameKz: 'Ақтөбе облысы', children: [] }


const Search: React.FC<IProps> = ({ searchValue, onChange, onClear, options, onSelectOption }) => {
  const { t } = useTranslation();
  const listRef = useRef<HTMLInputElement | null>(null);

  const [showOptions, setShowOptions] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState<any[]>([]);

  const handleOptionClick = (option: any) => {
    // if (option.fillingStatus !== 'COMPLETED' && option.fillingStatus !== 'IN_PROCESS') {
    //   return;
    // }
    onSelectOption(option);
    setShowOptions(false);
  };

  const handleClear = () => {
    onClear();
    setShowOptions(false);
  }

  useEffect(() => {
    options && options.length > 0
      && setFilteredOptions(options.sort(item => item.fillingStatus === 'COMPLETED' || item.fillingStatus === 'IN_PROCESS' ? -1 : 1));
  }, [options]);

  useEffect(() => {
    const filteredOptions = options.filter(option => {
      if (
        Object.values(option).some(
          value =>
            value &&
            typeof value === "string" &&
            value.toLowerCase().includes(searchValue.toLowerCase())
        )
      ) {
        return true;
      }

      if (
        option.children &&
        option.children.some((child: any) =>
          Object.values(child).some(
            value =>
              value &&
              typeof value === "string" &&
              value.toLowerCase().includes(searchValue.toLowerCase())
          )
        )
      ) {
        return true;
      }

      return false;
    });

    const filtered = filteredOptions.map(item => ({
      ...item,
      children: item.children.filter((child: any) => child[`name${getLang()}`].toLowerCase().includes(searchValue.toLowerCase()))
    }))

    setFilteredOptions(filtered);
  }, [options, searchValue]);


  useEffect(() => {
    searchValue.length > 0 && setShowOptions(true);
  }, [searchValue])

  useEffect(() => {
    const handleOutsideClick = (e: any) => {
      if (listRef.current && !listRef.current.contains(e.target)) {
        setShowOptions(false);
      }
    };

    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  return (
    <SearchInputWrapper ref={listRef}>
      <input
        type="text"
        placeholder={t('chooseSnp')}
        onChange={onChange}
        value={searchValue}
        onFocus={() => setShowOptions(true)}
      />
      <InputIcons>
        <img src={SearchIcon} alt="search icon" />
        <span className="divider"></span>
        <img src={XIcon} alt="clear icon" onClick={handleClear} />
      </InputIcons>
      {showOptions && filteredOptions && (
        <OptionsList>
          <div className="item" onClick={() => handleOptionClick(aktobeOblast)}>
            {aktobeOblast[`name${getLang()}`]}
          </div>
          {filteredOptions.map((option, index) => (
            <>
              <div className={`item ${option.fillingStatus !== 'NOT_STARTED' ? '' : ''}`} key={index} onClick={() => handleOptionClick(option)}>
                {option[`name${getLang()}`].trim()} 
              </div>
              <div>
                {option.children && option.children.length > 0 && option.children.map((child: any, index: any) => (
                  <div key={index} className={`item child ${['COMPLETED', 'IN_PROCCESS'].includes(child.fillingStatus) ? '' : ''}`} onClick={() => handleOptionClick(child)}>
                    {child[`name${getLang()}`]} 
                  </div>
                ))}
              </div>
            </>
          ))}
        </OptionsList>
      )}
    </SearchInputWrapper>
  );
};

export default Search