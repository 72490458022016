import styled, { css } from "styled-components";
import ArrowRight from '../../assets/icons/dark-arrow-right.svg';

const Wrapper = styled.div`
  height: 80vh;
  max-width: 85vw;
  overflow-x: auto;
  position: relative;
  padding-right: 0.625rem;

  form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 1.25rem;
    .grid-item {
      max-width: 33rem;

      .title {
        color: var(--text-color);
        font-family: Segoe UI;
        font-size:  0.75rem;
        &.bold {
          font-weight: 700;
          margin-bottom: 0.625rem;
        }

        &.with-chevron {
          display: flex;
          align-items: center;
          gap: 0.3125rem;

          svg {
            height: 0.625rem;
            width: 0.625rem;
            cursor: pointer;
          }
        }
      }
    }

    &.flex{
      display: flex;
      flex-direction: column;
      gap: 1.25rem;
      margin-bottom: 1.25rem;
    }
  }

  .images-grid-title {
    font-size: 1rem;
    margin-bottom: 0.625rem;

    .date {
      font-weight: 700;
    }
  }

  .images-row-title{
    padding: 0.625rem 0;
  }
 
  .images-grid {
    display: flex;
    gap: 0.625rem;
    max-width: 100%;
    flex-wrap: wrap;
    margin-bottom: 2rem;

    img {
      width: 12rem;
      height: 8.1875rem;
      border-radius: 0.625rem;
      cursor: pointer;
      position: relative;
      transition: transform 0.2s;
    }

    .image-item {
      position: relative;
      &:hover {
        .overlay {
          opacity: 1;
        }
      }
    }

    .overlay {
      position: absolute;
      color: #3399ff;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.625rem;
      cursor: pointer;
      font-size: 0.875rem;
      font-weight: 700;
      top: 0;
      left: 0;
      width: 12rem;
      height: 8.1875rem;
      border-radius: 0.625rem;
      background: rgba(255, 255, 255, 0.8); 
      z-index: 10000000; 
      opacity: 0; 
      transition: opacity 0.2s;

      path {
        stroke: #3399ff;
      }
    }

    &__item{
      display: flex;
      flex-direction: column;
      gap: 0.625rem;
      justify-content: flex-end;
      align-items: flex-end;
    }

    .image-date{
      font-size: 0.75rem;
      font-style: italic;
    }
  }

  ::-webkit-scrollbar {
    width: 2px;
    margin-right: 0.625rem;
  }
  
  ::-webkit-scrollbar-track {
    background-color: transparent; /* You can set a background color here if needed */
    margin-right: 0.625rem;

  }
  
  ::-webkit-scrollbar-thumb {
    background-color: var(--gray);
    border-radius: 5px; 
    border-top: 15px solid transparent;
    border-bottom: 35px solid transparent;
    background-clip: padding-box;
  }

  label {
    color: var(--shuttleGray);
    font-family: Segoe UI;
    font-size: 0.75rem;
  }

  .ru,
  .kz { 
    flex: 0.5;
    max-width: 33rem;
  }

  .photo,
  .image {
    display: flex;
    gap: 0.625rem;
    align-items: center;
    white-space: nowrap;
  }  

  .image {
    border-radius: 10px;
    border: 1px solid var(--borderGray);
    width: 100%;
    img {
      width: 3.75rem;
      height: 5rem;
      border-radius: 0.625rem;
    }
  }

  select {
    height: 2rem;
    padding: 0 0.9375rem;
    display: flex;
    align-items: center;
    color: var(--text-color);
    font-size: 0.875rem;
    font-weight: 600;
    border-radius: 5px;
    border: 1px solid var(--population-dynamic-border);
    background: var(--bg-white);
    cursor: pointer;
    &:focus {
      outline: none;
    }
  }

  .multi-select{
    margin: 0.625rem 0;
  }

  .error {
    border: 1px solid var(--deleteRed);
    border-radius: 5px;
  }
`

const FormGroup = styled.div<{ inputText?: string, type?: string }>`
  display: flex;
  flex-direction: column;
  gap: 0.375rem;
  margin-bottom: 1.25rem;
  position: relative;

  .error {
    border: 1px solid var(--deleteRed);
    border-radius: 5px;
  }

  .required {
    position: relative;
    &:after {
      content: '*';
      color: red;
      margin-left: 0.125rem;
    }
  }

  input {
    color: var(--text-color);
    font-size: 0.875rem;
    font-weight: 600;
    padding: 0.5rem 0.9375rem;
    border-radius: 5px;
    border: 1px solid var(--borderGray);
    background: var(--bg-white);
    height: 2rem;
    border: 1px solid var(--population-dynamic-border);
    
    &:focus {
      outline: none;
    }
    
    .capitalize {
      text-transform: capitalize;
    }

    .lowercase {
      text-transform: lowercase;
    }

    :disabled {
      cursor: not-allowed;
      background: var(--disabled);
    }

    .error {
      border: 1px solid var(--deleteRed);
      border-radius: 5px;
    }
  }

  .exactly-required {
    display: flex;
    gap: 0.625rem;
    align-items: center;

    > div {
      font-size: 0.75rem; 
    }

    input {
      flex: 1;
    }
  }


  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  ${({ inputText }) => inputText?.length && css`
      &:before {
        content: '${inputText}';
        position: absolute;
        right: 0.625rem;
        z-index: 100;
        font-size: 0.75rem;
      }
  `}

  ${({ type }) => type === 'row' && css`
    flex-direction: row;
    align-items: center;

    input {
      flex: 1;
    }
  `}
  
  .building{
    display: flex;
    gap: 0.625rem;
    align-items: center;
    input {
      flex: 1; 
    }
  }

  textarea {
    width: 100%;
    font-size: 0.75rem;
    padding: 0.5rem 0.9375rem;
    border-radius: 5px;
    border: 1px solid var(--borderGray);
    background: var(--bg-white);
    min-height: 2rem;
    color: var(--text-color);
    border: 1px solid var(--population-dynamic-border);
    &:focus {
      outline: none;
    }
  }

  .bold-label{
    font-weight: 700;
  }

  .readonly {
    border: none;
  }
`;

const FormBlock = styled.div<{ type: 'gray' | 'white', responsive?: boolean }>`
  border-radius: 5px;
  background: ${({ type }) => type === 'gray' ? 'var(--whiteSmoke)' : 'var(--bg-white)'};
  padding: 0.3125rem;
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
  margin-bottom: 1.25rem;
  color: var(--text-color);

  .capitalize {
    text-transform: capitalize;
  }

  .title {
    color: var(--text-color);
    font-family: Segoe UI;
    font-size:  0.75rem;
  }

  svg path {
    stroke: var(--text-color);
  }

  .increase {
    svg path {
      stroke: revert-layer;
    }
  }

  .row {
    display: flex;
    gap: 1.25rem;
    justify-content: space-between;
    align-items: center;
    white-space: nowrap;

    ${FormGroup} {
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      gap: 0.625rem;  

      label {
        white-space: nowrap;
      }

      input {
        height: 1.8125rem;
        max-width: 8rem;
      } 

      &.half {
        width: 100%;
        input{
          width: 50%;
          max-width: 50%;
        }
      }
    }
  }

  ${FormGroup} {
    margin-bottom: 0;

    input {
      font-weight: 400;
    }
  }

  ${p => p.type === 'gray' && css`
    min-height: 17.3125rem;
    border: 1px solid var(--white);
  `}

  ${p => p.type === 'white' && css`
    border: 1px solid var(--borderGray);
    background: var(--bg-white);
  `}

  ${p => p.responsive && css`
    min-height: 0;
  `}

  .buttons {
    display: flex;
    flex-direction: column;
    gap: 0.625rem;
  }

  .edit,
  .delete,
  .upload {
    display: flex;
    gap: 0.625rem;
    font-size: 0.875rem;
    align-items: center;
    cursor: pointer;
  }

  .delete {
    color: var(--deleteRed);
    svg { 
      path {
        stroke: var(--deleteRed);
      }
    }
  }

  .edit {
    color: #499A72;
    svg { 
      path {
        stroke: #499A72;
      }
    }
  };

  .upload {
    padding: 0.5rem 0.9375rem;
    color: var(--uploadBlue);
  };

  .grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0.625rem;

    ${FormGroup} {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      max-width: 100%;
    }

    input {
      max-width: 10rem;
    }
  }

  .roads {
    display: flex;
    gap: 0.625rem;
    flex-direction: column;
    input,
    select {
      max-width: 8rem;
      font-size: 0.75rem;
    }

    option {
      font-size: 0.75rem;
    }
  }

  .labels{
    display: flex;
    width: 100%;
    gap: 1.25rem;
  }

  .analytics {
    display: flex;
    gap: 1.25rem;
    width: 100%;
    align-items: center;
    width: 100%;
    flex-shrink: 1;
  }

  .child-grid{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 0.625rem;
    input {
      max-width: 10rem;
    }
  }

  .bold {
    font-weight: 700;
  }
  .road-values-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 0.625rem;
    
    > div {
      display: flex;
      gap: 0.625rem;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start; 
      .value {
        font-weight: 700;
      }
      > div {
        font-size: 0.75rem; 
      }
    }
  }
`

const Footer = styled.div`
  position: fixed;
  bottom: 0.3125rem;
  width: 100%;
  margin: 0 auto;
  height: 2.625rem;
  max-width: 78vw;
  border-radius: 0.3125rem;
  background: var(--lightYellow);
  padding: 0.3125rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: auto;
  z-index: 1000000000000;

  .buttons {
    display: flex;
    gap: 0.625rem;
    align-items: center;

    .checkbox{
      display: flex;
      align-items: center;
      padding-left: 0.625rem;
      gap: 0.625rem;
      label{
        font-size: 0.875rem;
      }
    }
  }
`;

const FooterButton = styled.button<{ variant?: 'cancel' | 'save' | 'go-up' | 'delete' }>`
  background: var(--white);
  color: var(--shuttleGray);
  border: none;
  height: 2rem;
  padding: 0.5rem 0.9375rem;
  display: flex;
  align-items: center;
  gap: 0.625rem;
  border-radius: 5px;
  font-size: 0.75rem;
  font-weight: 600;
  cursor: pointer;

  svg {
    height: 1rem;
    width: 1rem;
  }

  ${({ variant }) => variant === 'save' && css`
    background: #499A72;
    color: var(--white);
  `}

  ${({ variant }) => variant === 'delete' && css`
    background: var(--deleteRed);
    color: var(--white);

    svg path {
      stroke: var(--white);
    }
  `}

  ${({ variant }) => variant === 'cancel' && css`
    color: 98A3A9;
  `}
`

const HeaderWrapper = styled.div`
  display: flex;
  gap: 1.25rem;
  width: 100%;
  padding-bottom: 0.9375rem;
  border-bottom: 1px solid var(--borderGray);
  margin-bottom: 0.9375rem;
  align-items: center;
`

const BackButton = styled.button`
  all: unset; 
  height: 2rem;
  width: 5.3125rem;
  border-radius: 0.3125rem;
  background: var(--lightYellow);
  display: flex;
  gap: 0.625rem;
  align-items: center;
  justify-content: center;
  color: var(--text-color);
  font-family: Segoe UI;
  font-size: 0.875rem;
  font-weight: 600;
  cursor: pointer;
`

const LeftMenuWrapper = styled.div`
  width: 15rem;
  border-right: 1px solid var(--borderGray);
  background: var(--bg-white);
  height: 100%;
  padding: 0.625rem;
  display: flex;
  flex-direction: column;
  gap: 0.625rem;

  .statistics {
    display: flex;
    flex-direction: column;
    gap: 0.625rem;
    color: var(--text-color);
    .item {
      font-size: 0.875rem;
    }
  }
`

const LogoContainer = styled.img`
  padding: 0.625rem;
  border-radius:  0.625rem;
  background: var(--whiteSmoke);
  cursor: pointer;
`;

const Sections = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
  width: 100%;

  svg path {
    stroke: var(--text-color);
  }

  a {
    width: 100%;
    height: 2.125rem;
    padding: 0.625rem 0.9375rem;
    text-transform: uppercase;
    color: var(--shuttleGray);
    font-size:  0.75rem;;
    border-radius: 0.625rem;
    border: 1px solid var(--borderGray);
    display: flex;
    align-items: center;
    text-decoration: none;

    &.active{
      background: var(--lightYellow);
      position: relative;
      color: var(--text-color);
      font-weight: 700;
      border: none;
      &:after {
        content: url(${ArrowRight});
        height: 0.75rem;
        width: 0.375rem;
        position: absolute;
        right: 0.9375rem;
        top: 25%;
      }
    }
  }

  .add-info{
    a {
      border: none;
      text-transform: none;
      color: var(--mm-link);
      text-decoration: underline;
      cursor: pointer;
      font-size: 0.75rem;
    }
  }
`;

const SearchWrapper = styled.div`
  height: 2rem;
  flex: 1;
  position: relative;
  svg {
    position: absolute;
    right: 0.625rem;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }
`

const SearchInput = styled.input`
  width: 100%;
  height: 100%;
  padding: 0.5rem 0.625rem 0.5rem 0.9375rem;
  border-radius: 0.5rem;
  border: 1px solid var(--borderGray);

  &:focus {
    outline: none;
  }
`

const CustomSelectContainer = styled.div`
  position: relative;
  display: inline-block;
  border-radius: 0.25rem;
  background: var(--bg-white);
  overflow: hidden;
  height: 2rem;
  display: flex;
  align-items: center;
  gap: 0.625rem;
  color: var(--text-color);
  &:focus,
  &:active {
    outline: none;
  }
`;

const StyledSelect = styled.select`
  appearance: none;
  border: none;
  width: 100%;
  background-color: var(--bg-white);
  color: var(--text-color);
  font-family: Segoe UI;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  &:focus,
  &:active {
    outline: none;
  }

  &:disabled {
    all: unset;
    font-family: Segoe UI;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
  }

  input {
    background: var(--bg-white);
  }
`;

const TabsContainer = styled.div`
  display: flex;
  gap: 0.625rem;
  max-width: calc(100vw - 15rem);
  overflow-x: auto;

  ::-webkit-scrollbar {
    height: 2px; /* Set the width of the horizontal scrollbar */
    margin-bottom: 0.625rem; /* Add margin to keep space between content and scrollbar */
  }
  
  ::-webkit-scrollbar-track {
    background-color: transparent; /* Set the background color for the scrollbar track */
    margin-bottom: 0.625rem; /* Add margin to match scrollbar height */
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: var(--gray); /* Set the color of the scrollbar thumb */
    border-radius: 5px;
    border-left: 15px solid transparent; /* Set left and right borders for the thumb */
    border-right: 35px solid transparent;
    background-clip: padding-box;
  }
`;

const Tab = styled.div<{ isSelected: boolean }>`
  height: 1.875rem;
  padding: 0.5rem 0.625rem;
  border-radius: 5px;
  border: 1px solid var(--borderGray);
  font-family: Segoe UI;
  font-size: 0.75rem;
  color: var(--shuttleGray);
  font-weight: 500;
  cursor: pointer;
  white-space: nowrap;
  text-transform: uppercase;

  ${({ isSelected }) => isSelected && css`
    background: var(--lightYellow);
    border: none;
    color: var(--text-color);
    font-weight: 700;
  `}
`;

const AddPhotoButton = styled.div`
  display: flex;
  width: 12rem;
  height: 8.1875rem;
  justify-content: center;
  align-items: center;
  border-radius: 0.625rem;
  background: var(--whiteSmoke);
  border: 1px solid var(--population-dynamic-border);
  cursor: pointer;
`;

export {
  Wrapper,
  FormGroup,
  FormBlock,
  HeaderWrapper,
  BackButton,
  LeftMenuWrapper,
  LogoContainer,
  Sections,
  SearchWrapper,
  SearchInput,
  CustomSelectContainer,
  StyledSelect,
  TabsContainer,
  Tab,
  Footer,
  FooterButton,
  AddPhotoButton
} 